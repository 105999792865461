/*eslint-disable*/
import React from "react";
import { Formik } from "formik";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import PinDrop from "@material-ui/icons/PinDrop";
import Hours from "@material-ui/icons/WatchLater";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax";
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success";
import useNetlifyFormHandler from "hooks/useNetlifyFormHandler";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import CustomPhoneInput from "components/CustomPhoneInput/CustomPhoneInput";
import { validatePhoneFormat } from "components/CustomPhoneInput/CustomPhoneInput";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import CustomSelect from "components/CustomSelect/CustomSelect";
import allLocations from "allLocations";

const useStyles = makeStyles(contactUsStyle);
const useJsStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const locations = allLocations.map(({ name }) => name);

export default function ContactUsPage() {
  const [formProps, onSubmit, response, error] = useNetlifyFormHandler(
    "contact"
  );
  const [noticeModal, setNoticeModal] = React.useState(false);

  React.useEffect(() => {
    if (!response) {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    } else if (!!response) setNoticeModal(true);
  }, [response]);
  const classes = useStyles();
  const jsClasses = useJsStyles();

  return (
    <div>
      <Parallax image={require("assets/img/mural.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Contact Us</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Send us a message</h2>
            <GridContainer>
              <GridItem md={6} sm={6}>
                <p>
                  Do you need more information? Do you have questions for us?
                  Send us a message or give us a call and we will respond
                  quickly! Thank you for the interest!
                  <br />
                  <br />
                </p>
                <Formik
                  initialValues={{
                    email: "",
                    name: "",
                    phone: "",
                    message: "",
                    location: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.name) errors.name = "Required";
                    if (!values.email) {
                      errors.email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }
                    if (!values.phone) errors.phone = "Required";
                    else if (!validatePhoneFormat(values.phone)) {
                      errors.phone =
                        "Invalid phone number. Please use the format (123) 456-7890";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values).finally(() => {
                      setSubmitting(false);
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      {...formProps}
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                    >
                      {error && (
                        <Danger>
                          There was an error saving your submission. Please
                          check that the information you entered is correct and
                          try again.
                        </Danger>
                      )}
                      <CustomInput
                        labelText="Your Name"
                        id="name"
                        error={errors.name && touched.name}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                          defaultValue: values.name,
                          name: "name",
                        }}
                      />
                      {errors.name && touched.name && (
                        <Danger>{errors.name}</Danger>
                      )}
                      <CustomInput
                        labelText="Email address"
                        id="email"
                        error={errors.email && touched.email}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                          defaultValue: values.email,
                          name: "email",
                          type: "email",
                        }}
                      />
                      {errors.email && touched.email && (
                        <Danger>{errors.email}</Danger>
                      )}
                      <CustomPhoneInput
                        labelText="Phone"
                        id="phone"
                        error={errors.phone && touched.phone}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                          defaultValue: values.phone,
                          name: "phone",
                          type: "tel",
                        }}
                      />
                      {errors.phone && touched.phone && (
                        <Danger>{errors.phone}</Danger>
                      )}
                      <CustomSelect
                        labelText="Which location would you like to be seen at?"
                        id="location"
                        error={errors.location && touched.location}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                          name: "location",
                          value: values.location,
                          children: [
                            locations.map((location) => (
                              <MenuItem value={location} key={location}>
                                {location}
                              </MenuItem>
                            )),
                          ],
                        }}
                      ></CustomSelect>
                      {errors.location && touched.location && (
                        <Danger>{errors.location}</Danger>
                      )}
                      <CustomInput
                        labelText="Your message"
                        id="message"
                        error={errors.message && touched.message}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 6,
                          onChange: handleChange,
                          onBlur: handleBlur,
                          defaultValue: values.message,
                          name: "message",
                        }}
                      />
                      {errors.message && touched.message && (
                        <Danger>{errors.message}</Danger>
                      )}
                      <div className={classes.textCenter}>
                        <Dialog
                          classes={{
                            root: jsClasses.modalRoot,
                            paper: jsClasses.modal,
                          }}
                          open={noticeModal}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={() => setNoticeModal(false)}
                          aria-labelledby="notice-modal-slide-title"
                          aria-describedby="notice-modal-slide-description"
                        >
                          <DialogTitle
                            id="notice-modal-slide-title"
                            disableTypography
                            className={jsClasses.modalHeader}
                          >
                            <Button
                              simple
                              className={jsClasses.modalCloseButton}
                              key="close"
                              aria-label="Close"
                              onClick={() => setNoticeModal(false)}
                            >
                              {" "}
                              <Close className={jsClasses.modalClose} />
                            </Button>
                            <h4 className={jsClasses.modalTitle}>Thank you!</h4>
                          </DialogTitle>
                          <DialogContent
                            id="notice-modal-slide-description"
                            className={jsClasses.modalBody}
                          >
                            <p>
                              <strong>We've received your information!</strong>{" "}
                              A member of the Buckner team will reach out within
                              2 business days.
                            </p>
                            <p>
                              If you have more urgent questions, don{"'"}t
                              hesitate to contact us by phone! We
                              {"'"}re here to help!
                            </p>
                          </DialogContent>
                          <DialogActions
                            className={
                              jsClasses.modalFooter +
                              " " +
                              jsClasses.modalFooterCenter
                            }
                          >
                            <Button
                              onClick={() => setNoticeModal(false)}
                              color="primary"
                              round
                            >
                              Sounds Good
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <Button
                          color="primary"
                          round
                          type="submit"
                          disabled={isSubmitting || !!response}
                        >
                          Submit
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </GridItem>
              <GridItem md={4} sm={4} className={classes.mlAuto}>
                {allLocations.map((location) => (
                  <InfoArea
                    className={classes.info}
                    title={location.name}
                    description={
                      <p>
                        {location.address.map((line) => (
                          <>
                            {line}
                            <br />
                          </>
                        ))}
                        Phone:{" "}
                        <a href={`tel:+1 ${location.phone}`}>
                          {location.phone}
                        </a>
                        <br />
                        Fax: {location.fax}
                        <p>
                          {location.hours.map((line) => (
                            <>
                              {line}
                              <br />
                            </>
                          ))}
                          {location.aptOnly && (
                            <>
                              * <em>By appointment only</em>
                            </>
                          )}
                        </p>
                      </p>
                    }
                    icon={PinDrop}
                    iconColor="primary"
                  />
                ))}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
