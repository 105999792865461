/*eslint-disable*/
import React from "react";
import { Formik } from "formik";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Email from "@material-ui/icons/Email";
import Face from "@material-ui/icons/Face";
import Phone from "@material-ui/icons/Phone";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Danger from "components/Typography/Danger";
import useNetlifyFormHandler from "hooks/useNetlifyFormHandler";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/getStartedPageStyle.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import CustomPhoneInput from "components/CustomPhoneInput/CustomPhoneInput";
import { validatePhoneFormat } from "components/CustomPhoneInput/CustomPhoneInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import allLocations from "allLocations";

const useStyles = makeStyles(loginPageStyle);
const useJsStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
const locations = allLocations.map(({ name }) => name);

export default function GetStartedForm() {
  const [formProps, onSubmit, response, error] = useNetlifyFormHandler(
    "get-started"
  );

  const [noticeModal, setNoticeModal] = React.useState(false);

  React.useEffect(() => {
    if (!!response) setNoticeModal(true);
  }, [response]);

  const classes = useStyles();
  const jsClasses = useJsStyles();
  return (
    <Card>
      {response && <>Responded!</>}
      <Formik
        initialValues={{
          email: "",
          name: "",
          phone: "",
          socketAge: "",
          location: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) errors.name = "Required";
          if (!values.socketAge) errors.socketAge = "Required";
          if (values.socketAge === "I am a current patient")
            errors.socketAge = "Please call (601) 944-1130 instead";
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.phone) errors.phone = "Required";
          else if (!validatePhoneFormat(values.phone)) {
            errors.phone =
              "Invalid phone number. Please use the format (123) 456-7890";
          }
          if (!values.location) errors.location = "Required";
          console.log(values);
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values).finally(() => {
            setSubmitting(false);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form
            className={classes.form}
            {...formProps}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            {error && (
              <Danger>
                There was an error saving your submission. Please check that the
                information you entered is correct and try again.
              </Danger>
            )}
            <CardHeader color="primary" signup className={classes.cardHeader}>
              <h4 className={classes.cardTitle}>Get Started</h4>
            </CardHeader>
            <p className={classes.description + " " + classes.textCenter}>
              We offer free consultations to new patients. We want to provide
              the most comfortable, convenient care for our Buckner patients.
              Please provide some basic contact information below to get started
              and we'll contact you shortly.
            </p>
            <CardBody signup>
              <CustomInput
                id="name"
                labelText={touched.name && "Name"}
                error={errors.name && touched.name}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "Name...",
                  type: "text",
                  startAdornment: (
                    <InputAdornment position="start">
                      <Face className={classes.inputIconsColor} />
                    </InputAdornment>
                  ),
                  onChange: handleChange,
                  onBlur: handleBlur,
                  defaultValue: values.name,
                  name: "name",
                }}
              />
              {errors.name && touched.name && <Danger>{errors.name}</Danger>}
              <CustomInput
                id="email"
                labelText={touched.email && "Email"}
                error={errors.email && touched.email}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "Email...",
                  type: "email",
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email className={classes.inputIconsColor} />
                    </InputAdornment>
                  ),
                  onChange: handleChange,
                  onBlur: handleBlur,
                  defaultValue: values.email,
                  name: "email",
                }}
              />
              {errors.email && touched.email && <Danger>{errors.email}</Danger>}
              <CustomPhoneInput
                id="phone"
                labelText={touched.phone && "Phone"}
                error={errors.phone && touched.phone}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "Phone...",
                  type: "tel",
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone className={classes.inputIconsColor} />
                    </InputAdornment>
                  ),
                  onChange: (event) => {
                    handleChange(event);
                  },
                  onBlur: handleBlur,
                  defaultValue: values.phone,
                  name: "phone",
                }}
              />
              {errors.phone && touched.phone && <Danger>{errors.phone}</Danger>}
              <CustomSelect
                labelText="Which location would you like to be seen at?"
                id="location"
                error={errors.location && touched.location}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "location",
                  value: values.location,
                  children: [
                    locations.map((location) => (
                      <MenuItem value={location} key={location}>
                        {location}
                      </MenuItem>
                    )),
                  ],
                }}
              ></CustomSelect>
              {errors.location && touched.location && (
                <Danger>{errors.location}</Danger>
              )}
              <FormControl component="fieldset" error={!!errors.socketAge}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={
                        values.socketAge ===
                        "I am a new amputee or do not have a socket"
                      }
                      onChange={handleChange}
                      value="I am a new amputee or do not have a socket"
                      name="socketAge"
                      aria-label="I am a new amputee or do not have a socket"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="I am a new amputee or do not have a socket"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={values.socketAge === "Less than 6 months"}
                      onChange={handleChange}
                      value="Less than 6 months"
                      name="socketAge"
                      aria-label="Less than 6 months"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="Less Than 6 Months"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={values.socketAge === "6 Months to 2 Years"}
                      onChange={handleChange}
                      value="6 Months to 2 Years"
                      name="socketAge"
                      aria-label="6 Months to 2 Years"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="6 Months to 2 Years"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={values.socketAge === "More Than 2 Years"}
                      onChange={handleChange}
                      value="More Than 2 Years"
                      name="socketAge"
                      aria-label="More Than 2 Years"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="More Than 2 Years"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={values.socketAge === "I am a current patient"}
                      onChange={handleChange}
                      value="I am a current patient"
                      name="socketAge"
                      aria-label="I am a current patient"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="I am a current patient"
                />
              </FormControl>
              {errors.socketAge && <Danger>{errors.socketAge}</Danger>}
            </CardBody>
            <div className={classes.textCenter}>
              <Dialog
                classes={{
                  root: jsClasses.modalRoot,
                  paper: jsClasses.modal,
                }}
                open={noticeModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setNoticeModal(false)}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
              >
                <DialogTitle
                  id="notice-modal-slide-title"
                  disableTypography
                  className={jsClasses.modalHeader}
                >
                  <Button
                    simple
                    className={jsClasses.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => setNoticeModal(false)}
                  >
                    {" "}
                    <Close className={jsClasses.modalClose} />
                  </Button>
                  <h4 className={jsClasses.modalTitle}>Thank you!</h4>
                </DialogTitle>
                <DialogContent
                  id="notice-modal-slide-description"
                  className={jsClasses.modalBody}
                >
                  <p>
                    <strong>We've received your information!</strong> A member
                    of the Buckner team will reach out within 2 business days.
                  </p>
                  <p>
                    If you have more questions, don{"'"}t hesitate to contact
                    us! We
                    {"'"}re here to help!
                  </p>
                </DialogContent>
                <DialogActions
                  className={
                    jsClasses.modalFooter + " " + jsClasses.modalFooterCenter
                  }
                >
                  <Button
                    onClick={() => setNoticeModal(false)}
                    color="primary"
                    round
                  >
                    Sounds Good
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                simple
                color="primary"
                size="lg"
                type="submit"
                disabled={isSubmitting || !!response}
              >
                Get started
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Card>
  );
}
